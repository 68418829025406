import {PersonSex, ResidencyStatus, SSNType, StateIDSubtype} from "@/constants/PersonConstants";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import AddressDTO from "@/dto/AddressDTO";
import {BankAccountType} from "@/dto/BankAccountType";
import {HolderType} from "@/dto/HolderType";

export default class CompleteREREmployeePayload {

    id: number | null = null;
    residencyStatus: ResidencyStatus | null = null;
    personId: number | null = null;
    employeeId: number | null = null;
    employmentStart: Date | null = null;
    firstName: string | null = null;
    lastName: string | null = null;
    sex: PersonSex | null = null;
    countryOfBirth: string | null = null;
    dateOfBirth: string | null = null;
    nationality?: string | null = null;
    ead?: EADDataDTO | null = null;
    greenCard?: GreenCardDataDTO | null = null;
    passport?: CitizenPassportDataDTO | null = null;
    ssn: SSNCardDataDTO | null  = null;
    stateID: StateIDDataDTO | null = null;
    bankAccount: BankAccountData | null = null;
    isW4Required = true;
    foreignPassport: ForeignPassportDataDTO | null = null;

    constructor(init?: Partial<CompleteREREmployeePayload>) {
        Object.assign(this, init);
    }

}

export class EADDataDTO {

    uscisCode: string | null = null;
    category: string | null = null;
    cardId: string | null = null;
    termsAndConditions: string | null = null;
    validFrom: string | null = null;
    cardExpires: string | null = null;
    otherNotes: string | null = null;
    front: FileMetaDTO | null = null;
    back: FileMetaDTO | null = null;

    constructor(init?: Partial<EADDataDTO>) {
        Object.assign(this, init);
    }
}

export class GreenCardDataDTO {

    uscisCode: string | null = null;
    category: string | null = null;
    residentSince: string | null = null;
    cardExpires: string | null = null;
    cardNumber: string | number | null = null;
    front: FileMetaDTO | null = null;
    back: FileMetaDTO | null = null;

    constructor(init?: Partial<GreenCardDataDTO>) {
        Object.assign(this, init);
    }
}

export class CitizenPassportDataDTO {

    documentNumber: string | null = null;
    issueDate: string | null = null;
    expirationDate: string | null = null;
    file: FileMetaDTO | null = null;

    constructor(init?: Partial<CitizenPassportDataDTO>) {
        Object.assign(this, init);
    }
}

export class SSNCardDataDTO {

    ssn: string | null = null;
    file: FileMetaDTO | null = null;
    type: SSNType | null = null;

    constructor(init?: Partial<SSNCardDataDTO>) {
        Object.assign(this, init);
    }
}

export class StateIDDataDTO {

    state: string | null = null;
    subtype: StateIDSubtype | null = null;
    documentNumber: string | null = null;
    address = new AddressDTO();
    issueDate: string | null = null;
    expirationDate: string | null = null;
    otherNotes: string | null = null;
    front: FileMetaDTO | null = null;
    back: FileMetaDTO | null = null;

    constructor(init?: Partial<StateIDDataDTO>) {
        Object.assign(this, init);
    }
}

export class BankAccountData {
    accountNumber: string | null = null;
    bankName: string | null = null;
    routingNumber: string | null = null;
    state: string | null = null;
    accountHolder: string | null = null;
    accountType = BankAccountType.SAVING;
    holderType = HolderType.INDIVIDUAL;
}

export class ForeignPassportDataDTO {
    title: FileMetaDTO | null = null;
    issuedByCountry : string | null = null;
    comment: string | null = null;
}